<template>
  <v-container fluid>
    <v-data-table 
      :headers="headers"
      :items="courses" 
      :search="search"
      sort-by="calories" 
      class="mt-4"
    >
      <template v-slot:top>
        <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
      </template>
      <template v-slot:[`header.brazilian`]>
        <country-flag country="br"  size="normal"></country-flag>
      </template> 
      <template v-slot:[`header.argentine`]>
        <country-flag country="ar"  size="normal"></country-flag>
      </template>
      <template v-slot:[`header.colombian`]>
        <country-flag country="col"  size="normal"></country-flag>
      </template>
      <template v-slot:[`header.paraguayan`]>
        <country-flag country="py"  size="normal"></country-flag>
      </template>
      <template v-slot:[`header.uruguayan`]>
        <country-flag country="uy"  size="normal"></country-flag>
      </template> 
      <template v-slot:[`header.other`]>
          <v-icon size="28" color="grey">mdi-diving-scuba-flag</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn fab small class="elevation-0" max-width='20' max-height='20' color="blue-grey lighten-5" @click="showCourseDetails(item)">
          <v-icon size="20">mdi-information-variant</v-icon>
        </v-btn>
      </template>
    </v-data-table>
      
    <dialog-show-course-details
      :dialog="dialog_course_details"
      :course="course"
      @closeDialog="handleCloseDialog"
    />
  </v-container>
</template>

<script>
import Api from '@/api/index'
import { mapActions } from 'vuex'

export default {
  name: 'DoneFormsEnrollmentCounterTable',
  components: {
     CourseDetailsDialog: () => import ('../../shared/CourseDetailsDialog.vue'),
     DialogShowCourseDetails: () => import ('../../candidate_select_done_forms/select_components/DialogShowCourseDetails.vue'),
  },
  props: {
    current_year: {
      type: Number,
    },
  },
  data: () => ({
    headers: [
      { text: 'Nome do Curso', value: 'name'},
      { text: 'Brasil', value: 'brazilian', align: 'center', filterable: false },
      { text: 'Argentina', value: 'argentine', align: 'center', filterable: false },
      { text: 'Colombia', value: 'colombian', align: 'center', filterable: false },
      { text: 'Paraguai', value: 'paraguayan', align: 'center', filterable: false },
      { text: 'Uruguai', value: 'uruguayan', align: 'center', filterable: false },
      { text: 'Outros', value: 'other', align: 'center', filterable: false },
      { text: 'Informações', value: 'actions', align: 'center', sortable: false },
    ],
    course: {},
    courses: [],
    search: '',
    dialog_course_details: false
  }),
  methods: {
    getData(){
      this.getDashCounter()
    },
    showCourseDetails(item){
      Api.Course.show(item.course_id).then(r=>r.data).then(d=>{ this.course = d, this.dialog_course_details = true })
    },
    handleCloseDialog(){
      this.dialog_course_details = false
    },
    getDashCounter(kind='done_form_enrollment_country', filter = { country_counter_options: 'all_enrollment_by_course', current_year: this.current_year }){
      let courses_mapped = []

      Api.DashCounter.show(kind, filter).then(r=>r.data).then(d=>{
        d.map((obj) => {
          courses_mapped.push({
            brazilian: obj.country_count.brazilian,
            argentine: obj.country_count.argentine,
            colombian: obj.country_count.colombian,
            paraguayan: obj.country_count.paraguayan,
            uruguayan: obj.country_count.uruguayan,
            other: obj.country_count.other,
            name: obj.course_name,
            course_id: obj.course_id
          })
        })
        this.courses = courses_mapped

        if(courses_mapped.length > 0) {
          this.$emit('empityData', false)
        }else{
          this.$emit('empityData', true)
        }

      }).catch((err) => this.addNotification({type: 'error', msg: `${err}`}));
    },
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  watch: {
    current_year(){
      this.getData()
    }
  },
  mounted () {
    this.getData();
  },
}
</script>

<style scoped>

</style>